<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="plain">
        <div class="container pt-12 pl-5 pr-4 mx-auto mb-4 lg:pr-20">
          <h1 class="mt-4 mb-6 font-extrabold text-center lg:mb-10 text-mhead lg:text-head">Terms of use</h1>
          <!-- <p
            class="font-bold"
          >Important notice: This site does not provide healthcare advice. Consult your physician before using any splint.</p>-->

          <p>By reading this website, you acknowledge that you are responsible for your own health decisions.</p>

          <p>All information and resources found on ArmadilloMD.com are based on the opinions of the authors and are meant to provide general information on different splint types. Readers should consult their own doctor or health care professional for medical emergencies and treatment advice. No information on this site should be relied upon to make a medical diagnosis or determine a treatment for a medical condition. The information on this website is not intended to replace a one-on-one relationship with a qualified health care professional and is not intended as medical advice. No information on this site should be used to diagnose, treat, prevent or cure any disease or condition.</p>

          <p>None of the posts and articles on ArmadilloMD.com may be re-printed without express written permission. Excerpts/quotes (10% or less) may be reprinted as long as all links are left intact and ArmadilloMD.com and its content page are linked to with a live hyperlink.</p>
          <!-- <p>While the links on ArmadilloMD.com are purely for informational purposes, we do earn a small commission on links to some products and services. We do not write paid posts. The thoughts and opinions are our own and not of those of any sponsor or advertiser.</p> -->

          <p>All splints have been purchased by ArmadilloMD and have been independently reviewed by experts. While the links on ArmadilloMD.com are purely for informational purposes, as an Amazon Associate, we do earn a small commission from qualifying purchases. We do not write paid posts. The thoughts and opinions are our own and not of those of any sponsor or advertiser.</p>

          <p>All written material and images copyrighted 2020 and beyond.</p>

          <p>
            <b>Disclosures:</b>
            The owners and authors of this website have no conflicts of interest. Affiliations include: Broth LLC; Hospital for Special Surgery; Imagen Technologies; The Journal of Hand Surgery; and the Pediatric Hand International Society of Surgeons.
          </p>
        </div>
      </div>
    </div>
    <footer-md class="px-10 mt-10 text-gray-700 lg:px-0 lg:mt-0" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    "footer-md": Footer,
  },
};
</script>
